<template>
    <v-form height="100%" v-model="valid">
        <block v-if="loading"></block>
        <v-card>
            <v-card-title>
                <h3>Realizar abono a crédito</h3>
            </v-card-title>
            <v-card-text>
                <p>Para realizar abonos a las facturas, modificar la celda (Abono a realizar)</p>
                <DxDataGrid
                    ref="dxDataGrid"
                    :dataSource="invoicePendingToPay"
                    :showBorders="true"
                    :scrolling="{ useNative: true }"
                    :allow-column-resizing="true"
                    column-resizing-mode="widget"
                    :column-min-width="50"
                    :column-auto-width="true"
                    @cellClick="handleCellClick"
                >
                    <DxColumn data-field="PaymentToBeMade" caption="Abono a realizar" data-type="number">
                        <DxFormat typed="fixedPoint" :precision="2"/>
                        <DxPatternRule :pattern="paymentPattern"
                        message="El abono no puede tener números negativos" />
                        <DxAsyncRule :validation-callback="handlePayment"
                        message="La cantidad del abono no puede exceder al pendiente de la factura" />
                    </DxColumn>
                    <DxColumn cell-template="pending-pay" :allow-editing="false" caption="Pendiente"/>
                    <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click"
                    mode="cell" />
                    <DxColumn dataField="ID_Invoice" :allow-editing="false" caption="ID de Factura" width="175" />
                    <DxColumn dataField="CodigoGeneracion" :allow-editing="false" caption="Código Generación" width="320" />
                    <DxColumn dataField="InvoiceDate" :allow-editing="false" caption="Fecha de emisión" width="150" />
                    <DxColumn dataField="PaymentEndDate" :allow-editing="false" caption="Fecha de vencimiiento" width="175" />
                    <DxColumn cell-template="total-pay" :allow-editing="false" caption="Adeudado" />
                    <template #total-pay="{ data }">
                        {{ data.data.InvoiceTotalPagar.toFixed(2) }}
                    </template>
                    <template #pending-pay="{ data }">
                        {{ data.data.PendingToPay.toFixed(2) }}
                    </template>
                    <DxColumn cell-template="canceled" :allow-editing="false" caption="Abonado" />
                    <template #canceled="{ data }">
                        {{ data.data.Canceled.toFixed(2) }}
                    </template>
                    <DxColumn cell-template="expired-inv" :allow-editing="false" caption="Expirada"/>
                    <template #expired-inv="{ data }">
                        <div v-if="data.data.IsInvoiceExpired == 1" class="text-danger">
                        <v-icon
                        
                        color="error"
                        >
                            mdi-alert-circle
                        </v-icon>
                        </div>
                        <div v-else class="text-success">
                        <v-icon
                        color="success"
                        >
                            mdi-check-circle
                        </v-icon>
                        </div>
                    </template>
                </DxDataGrid>
                <v-row class="mt-2">
                    <v-col col="12" md="3">
                        <v-text-field
                        v-model="form.TotalPayment"
                        label="Total abonado"
                        outlined
                        dense
                        readonly
                        ></v-text-field>
                    </v-col>
                    <v-col col="12" md="3">
                        <v-select v-model="form.PaymentType" outlined dense attach :items="paymentTypes" required 
                        :rules="[
                            (v) => !!v || 'El método de pago es requerido',
                        ]"
                            item-text="Valores" label="Método de pago" class="fast-ease-in-transitioned-element">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="3" v-if="form.PaymentType != 'Billetes y monedas' && form.PaymentType != undefined">
                        <v-text-field outlined dense v-model="form.NReference" label="N. Referencia" required 
                        :rules="[
                            (v) => !!v || 'El número de referencia es requerido',
                        ]">
                        </v-text-field>
                        </v-col>
                    <v-col col="12" md="3">
                        <date-picker label="Fecha de abono" @dateSelected="(param) => (form.PaymentDate = param)" :disbaled="(isCarsem ? true : false) && (!isAdmin)" dense>
                        </date-picker>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="3">
                        <v-text-field outlined dense v-model="form.ExtraReference" label="Referencia de pago adicional"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <CustomAutoComplete
                        v-if="sellersList.length > 0"
                        label="ID Vendedor"
                        v-model="form.ID_Seller"
                        :search="{ ...search }"
                        :items="sellersList"
                        :item-text="'ID_Seller'"
                        :filter-endpoint="$API.sellers.getAllSellersFiltered"
                        :item-value="'ID_Seller'"
                        @select="
                          (e) => {
                            form.ID_Seller = e;
                          }
                        "
                        @updateItems="updateSellersList"
                      />
                    </v-col>
                    <v-col cols="12" md="5">
                      <CustomAutoComplete
                        v-if="sellersList.length > 0"
                        label="Vendedor"
                        v-model="form.ID_Seller"
                        :search="{ ...search }"
                        :items="sellersList"
                        :item-text="'FullName'"
                        :filter-endpoint="$API.sellers.getAllSellersFiltered"
                        :item-value="'ID_Seller'"
                        @select="
                          (e) => {
                            form.ID_Seller = e;
                          }
                        "
                        @updateItems="updateSellersList"
                      />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col col="12" md="12">
                        <quill-editor v-model="form.Note" :options="quillOptions" @change="onEditorChange($event)"></quill-editor>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="closeModal" class="btndisable" >Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn :disabled="!valid" @click="addPaymentToAccountReceivable()" class="btnsave">Realizar abono</v-btn>
            </v-card-actions>
        </v-card>

        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </v-form>
</template>

<script>
    import { mapGetters } from "vuex";
    import DatePicker from "../../../components/DatePicker";
    import {DxColumn, DxDataGrid, DxFormat, DxEditing, DxPatternRule, DxAsyncRule} from 'devextreme-vue/data-grid';
    import { quillConfig } from "../../../helpers/quillconfig";
    import Alerts from '@/components/Alerts.vue'
    import Block from "@/components/Block";
    import CustomAutoComplete from "@/components/CustomAutoComplete.vue";
    import {
        VUE_APP_CARSEM_ID_KEYMASTER1,
        VUE_APP_CARSEM_ID_KEYMASTER2,
        VUE_APP_CARSEM_ID_KEYMASTER3
    } from "../../../config";
    import { getCookie } from "../../../helpers/misc";

    export default {
        name: "AccountsReceivableForm",
        components: {
            DxColumn, 
            DxDataGrid,
            DxFormat,
            DxEditing,
            DxPatternRule,
            DatePicker,
            DxAsyncRule,
            Alerts,
            Block,
            CustomAutoComplete
        },
        props: ['win', 'Customer', 'envType'],
        data() {
            return {
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                isCarsem: false,
                roleUser: JSON.parse(localStorage.getItem("user")).roles[0],
                isAdmin: false,
                form: {
                    ID_Business: this.Customer.ID_Business,
                    ID_Branch: this.Customer.ID_Branch,
                    ID_Customer: this.Customer.ID_Customer,
                    TotalPayment: 0
                },
                invoicePendingToPay: [],
                quillOptions: quillConfig,
                paymentPattern: /^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[0-9][0-9]*)$/i,
                originalCellValue: 0,
                valid: false,
                loading: false,
                sellersList: [],
                search: {}
            }
        },
        watch: {
            invoicePendingToPay: {
                handler: function (data) {
                    this.form.TotalPayment = 0
                    for(let i = 0; i < data.length; i++){
                        this.form.TotalPayment += data[i].PaymentToBeMade
                    }
                },
                deep: true
            }
        },  
        computed:{
            ...mapGetters(['getCatalogMH']),
            paymentTypes() {
              return this.getCatalogMH('FormaPago');
            },
        },
        async mounted(){
            await this.getInvoicePendingToPay()
            await this.getAllSellers();
            this.verifyCarsem();
            //console.log(this.roleUser);
            if(this.roleUser == 'ROLE_ADMIN') {
                this.isAdmin = true;
            }
        },
        methods: {
            verifyCarsem() {
                const keyMastersArray = JSON.parse(getCookie("ID_KeyMaster"));
                const specialKeyMasters =
                    keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER1) ||
                    keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER2) ||
                    keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER3);
                console.log("", specialKeyMasters);
                if (specialKeyMasters) {
                    this.isCarsem = true;
                    this.key++;
                } else {
                    this.isCarsem = false;
                }
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },

            closeAlert(){
                this.alert.show = false;

                if (this.alert.options == "closeModal") {
                    this.$emit("close");
                }
            },

            closeModal() {
                this.$emit("close");
            },

            async getInvoicePendingToPay() {
                this.$API.customers.getInvoicePendingToPay({ "ID_Customer": this.Customer.ID_Customer, "envType": this.envType })
                .then(response => {
                    this.invoicePendingToPay = response.map(item => ({...item, PaymentToBeMade: 0}))
                })
            },

            async handlePayment(params){
                const { data: { PendingToPay, PaymentToBeMade } } = params
                
                if(PaymentToBeMade > PendingToPay) {
                    return false
                }
                else{
                    return true
                }
            },

            handleCellClick(e){
                const val = e.data.PaymentToBeMade
                this.originalCellValue = val
            },
            
            async addPaymentToAccountReceivable(){
                this.loading = true
                if(this.form.TotalPayment == 0){
                    this.showAlert( "warning", "Error", 'No puede realizar un pago con monto 0');
                    this.loading = false;
                    return;
                }
                this.$refs.dxDataGrid.instance.saveEditData()
                .then(() => {
                    // if(this.form.TotalPayment == 0){
                    //     return;
                    // }
                    
                    this.$API.customers.addPaymentToAccountReceivable({ "invoiceCredits": this.invoicePendingToPay, "payment": this.form })
                    .then(async (response) => {
                        this.loading = false
                        await this.getInvoicePendingToPay()
                        this.form.Note = ""
                        this.showAlert("success", "Éxito", response.message, "closeModal")
                    })
                    .catch(() => {
                        this.loading = false
                        this.showAlert("danger", "Error", "Ocurrio un problema al realizar el abono, por favor intentarlo nuevamente")
                    })
                })
                .catch(() => {
                    this.loading = false
                    this.showAlert("danger", "Error", "Ocurrio un problema en actualizar la información de la tabla, por favor intentarlo nuevamente.")
                })
            },
            async getAllSellers() {
                try {
                    const result = await this.$API.sellers.getAllSellers();
                    this.sellersList = result;
                } catch (error) {
                    console.log("error en los sellers ", error);
                }
            },
            updateSellersList(payload) {
                this.sellersList = [...payload];
            },
        },

    }
</script>

<style scoped>

.btndelete {
  background-color: #E3191E !important;
  color: white;
}

.btn-add {
  background-color: #3498DB !important;
  color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

</style>